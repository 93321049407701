import React, { useState, useEffect } from 'react';
import { Form, Row, Col, FormText } from 'reactstrap';

import { jt } from 'ttag';

import FormField from './FormField';

import ValidateName from './ValidateName';
import ValidateTel from './ValidateTel';

import Loading from 'aulatime/src/components/Loading';

const ReferAFriendForm = ({ metadata, toggleModal = () => { return null; } }) => {
  //
  const dataBag = {
    value: '',
    error: '',
    valid: {},
    initialValue: ''
  }
  const initialState = {
    nameFriend: dataBag,
    telFriend: {
      ...dataBag,
      ...{ initialValue: '+34 ' }
    },
    firstNameReferrer: dataBag,
    lastNameReferrer: dataBag
  }

  const [state, setState] = useState(initialState);
  const [success, setSuccess] = useState(0);
  const [error, setError] = useState(0);
  const [loading, setLoading] = useState(false);
  const [counter, setCounter] = useState('');
  const [valid, setValid] = useState(false);

  //
  useEffect(
    () => {
      let {nameFriend, telFriend, firstNameReferrer, lastNameReferrer} = state;

      const invalid = (field) => {
        const { valid } = field;
        return JSON.stringify(valid) === JSON.stringify({ invalid: true }) ||
          JSON.stringify(valid) === JSON.stringify({});
      };

      const isInvalid = [nameFriend, telFriend, firstNameReferrer, lastNameReferrer].some(invalid);
      setValid(!isInvalid);

    }, [state]
  );

  const handleChange = (field, updatedState) => {
    setState({
      ...state,
      [field]: {
        ...state[field],
        ...updatedState
      }
    });
  }

  const handleSuccess = () =>{
    setSuccess(1);
    countdown(5);

    setTimeout(() => {

      setLoading(false);
      setState(initialState);
      setSuccess(0);
      toggleModal();

    }, 7000);
  }

  const handleError = () => {
    setError(1);
    countdown(5);

    setTimeout(() => {

      setLoading(false);
      setError(0);

    }, 7000);
  }

  const handleSubmit = e => {
    e.preventDefault();

    if (valid) {

      setLoading(true);

      fetch('/postmanp-referafri', {
        method: 'POST',
        body: JSON.stringify(
          {
            ...{
              nameFriend: state.nameFriend.value,
              telFriend: state.telFriend.value,
              firstNameReferrer: state.firstNameReferrer.value,
              lastNameReferrer: state.lastNameReferrer.value,
            },
            ...metadata
          }
        )
      }).then(
        (res) => {

          console.log(res);

          if (typeof res !== undefined) {
            if (typeof res.status !== undefined) {
              if (res.status === 200) {

                handleSuccess();
                return;

              }
            }
          }
          handleError();

          /**
           * ¡ Done
           */
        }
      ).catch(
        (err) => {

          console.error(err);
          handleError();

        }
      );
    }
  }

  const countdown = (n) => {
    setTimeout(() => {
      if (n === 0)  {
        setCounter('');
        return;
      }
      setCounter(n);
      return countdown(n - 1);
    }, 1000);
  }

  return (
    <>
      <div className={`two text-center ${!success && ('invisible')}`} style={{opacity: success}}>
        <h3 className="text-success mb-3">{jt`Success`}</h3>
        <p>{jt`Your message has been sent`}.</p>
        <p>{jt`We will be in touch very shortly`}.</p>
        <div style={{minHeight: '100px'}}><h1 className="text-success">{counter}</h1></div>
      </div>

      <div className={`three text-center ${!error && ('invisible')}`} style={{opacity: error}}>
        <h3 className="text-danger mb-3">{jt`Schucks...`}</h3>
        <p>{jt`There's no active connection right now`}.</p>
        <p>{jt`Please call us on`} <span className="lead text-danger">+34 644 80 37 77</span>.</p>
        <div style={{minHeight: '100px'}}><h1 className="text-danger">{counter}</h1></div>
      </div>

      <div className="one">
        <Form>
          <div className="row">

            <div className="col">
              <h3 className="text-primary-2">{jt`Friend`}</h3>
            </div>

          </div>
          <Row form>
            <Col md={6}>
              <FormField
                name="nameFriend"
                label={jt`Name`}
                placeholder={jt`Arya`}
                text={<FormText>{jt`Letters, hyphens and spaces`}.</FormText>}
                data={state.nameFriend}
                onChange={(field, updatedState) => handleChange(field, updatedState)}
                validate={() => ValidateName('nameFriend', state.nameFriend, handleChange)}
              />
            </Col>
            <Col md={6}>
              <FormField
                name="telFriend"
                label={jt`Tel.`}
                placeholder={jt`+34 654 321 098`}
                text={
                  <>
                    <FormText>{jt`Please use the format +123 (0)456789012`}.</FormText>
                    <FormText>{jt`Dashes, dots, spaces and parentheses can be used`}.</FormText>
                  </>
                }
                data={state.telFriend}
                onChange={(field, updatedState) => handleChange(field, updatedState)}
                validate={() => ValidateTel('telFriend', state.telFriend, handleChange)}
              />
            </Col>
          </Row>
          <div className="row">

            <div className="col">
              <h3>{jt`Current customer`}</h3>
            </div>

          </div>
          <Row form>
            <Col md={6}>
              <FormField
                name="firstNameReferrer"
                label={jt`First Name`}
                placeholder={jt`Arya`}
                text={<FormText>{jt`Letters, hyphens and spaces`}.</FormText>}
                data={state.firstNameReferrer}
                onChange={(field, updatedState) => handleChange(field, updatedState)}
                validate={() => ValidateName('firstNameReferrer', state.firstNameReferrer, handleChange)}
              />
            </Col>
            <Col md={6}>
              <FormField
                name="lastNameReferrer"
                label={jt`Last Name`}
                placeholder={jt`Stark`}
                text={<FormText>{jt`Letters, hyphens and spaces`}.</FormText>}
                data={state.lastNameReferrer}
                onChange={(field, updatedState) => handleChange(field, updatedState)}
                validate={() => ValidateName('lastNameReferrer', state.lastNameReferrer, handleChange)}
              />
            </Col>
          </Row>
          <div className="row align-items-center pt-3">
            <div className="col-auto">
              {!loading && (
                <button
                  type="button"
                  className={`btn btn-dark btn-lg${valid ? ' flashing' : ''}`}
                  onClick={e => handleSubmit(e)}>
                  {valid ? jt`Click here...` : jt`Send`}
                </button>
              )}
              {loading && (
                  <Loading/>
              )}
            </div>
            <div className="col"></div>
          </div>

        </Form>
      </div>
    </>
  );
}

export default ReferAFriendForm;
